import * as React from "react"

import useRocketChat, { HTTP_ORIGIN } from "./logic/useRocketChat"
import LastMessages from "./LastMessages"
import Input from "./Input"

import * as classess from "./chat.module.css"
import ChatContext from "./ChatContext"
import Thread from "./Thread"
import EmojiPicker from "./EmojiPicker"
import { Link } from "gatsby"

/** @typedef {import("./ChatContext.js").ChatContextValue} ChatContextValue */
/** @typedef {import("./ws.js").Message} Message */
/** @typedef {import("./logic/RCMessage.js").default} RCMessage */
/** @typedef {import("./ws.js").MessageEventHandler} MessageEventHandler */

const FETCH_MESSAGES_COUNT = 30
const USER_SECRET_TOKEN = `ZvkjrznGRCYcHSouErzEXC6gtRPQ-9Tllzq2gXG9_dM`
const USER_ID = `tLd8wvNHpSkaGxdRK`
// const USER_SECRET_TOKEN = `GaTUZpBaKkaX32nMDp54j0oVa2NAyQ6JjuzdiEQbEyX`
// const USER_ID = `gnfGQJFkKwozLGsuJ`

export default function Chat({ name, style }) {
  const [ user, setUser ] = React.useState({ token:USER_SECRET_TOKEN, id:USER_ID })

  const [ channels, setChannels ] = React.useState([])
  const [ activeChannel, setActiveChannel ] = React.useState( null )

  /** @type {[RCMessage, React.Dispatch<React.SetStateAction<RCMessage>>>]} */
  const [ activeThread, setActiveThread ] = React.useState( null )

  /** @type {[Map<{ id:string }>, React.Dispatch<React.SetStateAction<Map<{ id:string }>>>]} */
  const [ threads, setThreads ] = React.useState( new Map() )

  /** @type {[RCMessage, React.Dispatch<RCMessage>]} */
  const [ msgToResponse, setMsgToResponse ] = React.useState( null )

  const cachedDataRef = React.useRef({
    users: new Map(),
  })

  const registerThread = id => setThreads( threads => {
    if (threads.has( id )) return threads

    threads.set( id, { id } )

    return new Map( threads )
  } )

  const rc = useRocketChat({
    channelId: activeChannel?.id,
    registerThread,
    userToken: user.token,
    userId: user.id,
  })

  /** @type {ChatContextValue} */
  const ctxValue = {
    rc,
    channelId: activeChannel?.id,
    activeThread, setActiveThread,
    msgToResponse, setMsgToResponse,
    threads,
    messagesQueryCount: FETCH_MESSAGES_COUNT,
    useCachedData( namespace, key ) {
      const [ data, setData ] = React.useState( null )
      const cachedData = cachedDataRef.current

      React.useEffect( () => {
        switch (namespace) {
          case `users`:
            if (cachedData.users.has( key )) {
              setData( cachedData.users.get( key ) )
            } else {
              cachedData.users.set( key, null )
              rc.getUserAvatar( key ).then( result => {
                cachedData.users.set( key, result )
                setData( result )
              } )
            }
        }
      }, [] )

      return data
    },
  }

  const inputTooltip = !msgToResponse ? null : (
    <div>
      <span className="is-bold">{msgToResponse.author.firstname} {msgToResponse.author.lastname}</span>
      <span>{`: `}{msgToResponse.content.length > 50 ? msgToResponse.content.slice( 0, 50 ) + `...` : msgToResponse.content}</span>
    </div>
  )

  const send = (value, attachments) => {
    if (!msgToResponse) return rc.sendMessage( { attachments }, value )

    setMsgToResponse( null )

    rc.sendMessage( `[ ](${HTTP_ORIGIN}/channel/${activeChannel.id}?msg=${msgToResponse.id}) ${value}` )
  }


  React.useEffect( () => {
    if (!rc) return

    rc.getUserChannels().then( rawChannels => {
      const channels = rawChannels.map( rchnl => ({ id:rchnl._id, name:rchnl.name }) )
      const initialChannel = channels.find( c => c.id === `GENERAL` ) ?? channels[ 0 ]

      setChannels( channels )
      setActiveChannel( initialChannel )
    } )

    return () => rc.close()
  }, [ !!rc, user.id ] )

  if (!rc) return null

  return (
    <article style={style} className={`${activeThread ? classess.isWithThread : ``} ${classess.component}`}>
      <ChatContext.Provider value={ctxValue}>
        {/* Pre render all emojis inside every another picker */}
        <EmojiPicker style={{ display:`none` }} />

        <nav className={classess.nav}>
          <ol className={classess.navItems}>
            {/* <li className={classess.navSpaceFillerPre} /> */}
            {
              channels.map( c => (
                <li key={c.id}>
                  <button
                    className={`${classess.navItem} ${activeChannel?.id === c.id ? classess.isActive : ``}`}
                    onClick={() => setActiveChannel( c )}
                  >
                    {c.name}
                  </button>
                </li>
              ) )
            }
            {/* <li className={classess.navSpaceFillerPost} /> */}
          </ol>

          <ol className={classess.navItems}>
            <li className={`${classess.navItem} minWidth-1140`}><Link style={{ color:`#0051ff` }} to="/">Główna</Link></li>
            <li className={`${classess.navItem} minWidth-1140`}><Link style={{ color:`#0051ff` }} to="/forum">Symulacja forum</Link></li>
            <li className={`${classess.navItem}`}>Użytkownicy testowi:</li>
            {
              [
                { token:`sZH44h3UkakFhE1u9tgWco33ra2iXxLqJj1PpmTQYRA`, id:`A6Gg84xbkzSubB7dY`, name:`Cokolwiek` },
                { token:`4D4QzOxmZFgCsH-0be5q32ZbhytMnOOf8GU1k0yk9zd`, id:`PzMc4gZ7T75HgNAhf`, name:`Testowy` },
                { token:`rhWtlTHkZm1WSAijX0J5OYlJwx9z_7pAKdnJF69d9Ki`, id:`LpuPjh8t5uuX7Z8Dk`, name:`Kaktus` },
              ].map( ({ token, id, name }) => (
                <li key={id}>
                  <button
                    className={`${classess.navItem} ${user.id === id ? classess.isActive : ``}`}
                    onClick={() => setUser({ token, id })}
                    children={name}
                  />
                </li>
              ) )
            }
          </ol>
        </nav>

        <article className={classess.channel}>
          <section className={classess.channelThread}>
            <LastMessages rc={rc} channelId={activeChannel?.id} />

            <Input name={name} rc={rc} response={inputTooltip} onSubmit={send} />
          </section>

          {activeThread && <Thread className={classess.messageThread} name={name} rc={rc} thread={activeThread} />}
        </article>
      </ChatContext.Provider>
    </article>
  )
}

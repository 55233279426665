import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import useWs from "./useWs"

/** @typedef {{ data:Object<string,unknown>, resolve:(value: any) => void}} WaitingRequest */
/** @typedef {(message:Message) => void} MessageEventHandler */
/** @typedef {Message} Message */

/** @type {WaitingRequest[]} */
const messagesToSend = []
/** @type {WaitingRequest[]} */
const authMessagesToSend = []

export const storedIDs = new Map()
export const getUID = (theme, collectionToSave) => {
  const id = theme
    ? `${theme}-${Date.now().toString( 36 )}`
    : Date.now().toString( 36 )

  if (collectionToSave) storedIDs.set( id, collectionToSave )

  return id
}

const getSenderReturnValue = (mayberParentResolver, promiseResolver) => {
  const promise = new Promise( promiseResolver )

  return typeof mayberParentResolver === `function`
    ? mayberParentResolver( promise )
    : promise
}

export default function useRocketChatWS({ secret, url, onMessage, channelId, DEBUG }) {
  const authId = useMemo( () => getUID(), [ secret ] )
  const [ isAuthorized, setAuthorized ] = useState( false )
  const isAuthorizedRef = useRef( false )
  const pendingRequestsRef = useRef( new Map() )
  const eventsHandlersRef = useRef({
    onMessage: [],
  })
  const ws = useWs({
    url,
    id: getUID( `rc-ws-obj` ),
    restartConditions: [ secret ],
    onCreate() {
      if (DEBUG) console.log( `%cCreated WS %c${this.id}`, `color:red`, `` )
    },
    onOpen() {
      if (DEBUG) console.log( `WS connected. WS ID: ${this.id}` )

      send({
        "msg": `connect`,
        "version": `1`,
        "support": [ `1` ],
      })

      send({
        "msg": `method`,
        "method": `login`,
        "id": authId,
        "params": [ { "resume":secret } ],
      })

      messagesToSend.splice( 0 ).forEach(
        ({ data, resolve }) => resolve( send( data, resolve ) ),
      )
    },
    onMessage({ data:dataJson }) {
      const data = JSON.parse( dataJson )

      if (!data) return

      if (data.msg === `ping`) {
        return authSend({ msg:`pong` })
      }

      if (data.msg === `result` && data.id == authId) {
        if (DEBUG) console.log( `Authorization data`, data )
        return setAuthorized( true )
      }

      const pendingReqs = pendingRequestsRef.current

      if (pendingReqs.has( data.id )) {
        const resolve = pendingReqs.get( data.id )

        if (typeof resolve === `function`) return resolve( data )
      }

      onMessage( data, eventsHandlersRef.current )
    },
    onClose( e ) {
      if (DEBUG) console.log( `%cClosed WS %c${this.id}`, `color:cyan`, `` )
      if (e.reason === `Normal closure`) this.restart()
    },
    onError: console.error,
  })


  const send = useCallback( async(data, saveOrResolver = false) => {
    if (ws.readyState != ws.OPEN) {
      if (DEBUG) console.log( `Not ready. WS ID: ${ws.id}; state: ${ws.readyState}; open state enum: ${ws.OPEN}` )

      return getSenderReturnValue(
        saveOrResolver,
        resolve => messagesToSend.push({ data, resolve:resolve }),
      )
    }

    ws.send( JSON.stringify( data ) )

    if (!saveOrResolver || !data.id) return

    return getSenderReturnValue(
      saveOrResolver,
      resolve => pendingRequestsRef.current.set( data.id, resolve ),
    )
  }, [ ws.id, ws.readyState ] )
  const authSend = useCallback( async(data, saveOrResolver = false) => {
    if (!isAuthorizedRef.current) {
      if (DEBUG) console.log( `Not authorized` )

      return getSenderReturnValue(
        saveOrResolver,
        resolve => authMessagesToSend.push({ data, resolve }),
      )
    }

    return send( data, saveOrResolver )
  }, [ isAuthorizedRef, send ] )
  const subChannel = useCallback( () => {
    if (DEBUG) {
      if (!channelId) console.log( `No channelId to subscribe` )
      else console.log( `Subscribe channel ${channelId}` )
    }

    if (!channelId) return

    const subId = getUID( `stream-room-messages` )

    authSend({
      "msg": `sub`,
      "id": subId,
      "name": `stream-room-messages`,
      "params": [
        channelId,
        false,
      ],
    })

    return subId
  }, [ channelId ] )
  const unsubChannel = useCallback( subId => {
    if (DEBUG) {
      if (!channelId) console.log( `No channelId to unsubscribe` )
      else console.log( `Unsubscribe channel ${channelId}` )
    }

    if (!channelId) return

    authSend({
      "msg": `unsub`,
      "id": subId,
    })

    return subId
  }, [ channelId ] )

  useEffect( () => {
    isAuthorizedRef.current = false
    setAuthorized( false )

    if (DEBUG) console.log( `Effect config execution. WS ID: ${ws.id}; state: ${ws.readyState}` )

    const subId = subChannel()

    return () => unsubChannel( subId )
  }, [ ws.id ] )

  useEffect( () => {
    const subId = subChannel()
    return () => unsubChannel( subId )
  }, [ channelId ] )

  useEffect( () => {
    if (!isAuthorized) return
    if (DEBUG) console.log( `Rocket chat Authorized. Messages to send: ${authMessagesToSend.length}` )

    isAuthorizedRef.current = true

    if (!authMessagesToSend.length) return

    authMessagesToSend.splice( 0 ).forEach(
      ({ data, resolve }) => resolve( authSend( data, resolve ) ),
    )
  }, [ isAuthorized ] )


  return {
    id: ws.id,
    send,
    authSend,


    /**
     * @param {string} eventName
     * @param {MessageEventHandler} handler
     */
    addEventListener( eventName, handler ) {
      if (!eventsHandlersRef.current) return

      switch (eventName) {
        case `message`: return eventsHandlersRef.current.onMessage.push( handler )

        default: return console.error( `Wrong event name` )
      }
    },


    /**
     * @param {string} eventName
     * @param {MessageEventHandler} handler
     */
    removeEventListener( eventName, handler ) {
      if (!eventsHandlersRef.current) return

      const arr = (() => {
        switch (eventName) {
          case `message`: return eventsHandlersRef.current?.onMessage
        }
      })()

      if (!arr) return console.error( `Wrong event name` )

      arr.splice( arr.indexOf( handler ), 1 )
    },


    close() {
      ws.close()
    },
  }
}

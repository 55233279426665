import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from "react"
import * as classess from "./chat.module.css"
import Message from './Message'
import List, { ListItem } from "../list/List"
import ChatContext from "./ChatContext"

/** @typedef {import("./logic/RCMessage.js").default} Message */
/** @typedef {ReturnType<import("./logic/useRocketChat").default>} UseRocketChatValue */

/** @param {{ rc:UseRocketChatValue }} param0 */
export default function LastMessages({ rc, channelId }) {
  const ctx = useContext( ChatContext )
  const staticStateRef = useRef({
    channelId,
    fixedScroll: true,
    downloading: false,
    upEnd: false,
    downEnd: true,
  })

  const sState = staticStateRef.current

  const [ , setReload ] = useState( 0 )
  const [ messages, setMessages ] = useState([])
  const setSortedMessages = messages => setMessages( msgs => {
    /** @type {Message[]} */
    const sortedMsgs = [  ...messages, ...msgs ]
      .filter( ({ id }, i, arr) => arr.findIndex( m => m.id === id ) === i )
      .sort( (a, b) => a.ts - b.ts )

    sortedMsgs.forEach( (msg, i, arr) => {
      msg.setPreviousMessage( arr[ i - 1 ] )
    } )

    // MORE PRECISE PREVIOUS MESSAGE SETTER
    //
    // sortedMsgs.forEach( (msg, i, arr) => {
    //   let prevMsg

    //   for (let j = i - 1;  j >= 0;  j--) {
    //     const maybePrevMsg = arr[ j ]

    //     if (msg.isInThread()) {
    //       if (maybePrevMsg.threadMsgId === msg.threadMsgId) prevMsg = maybePrevMsg
    //     } else if (!maybePrevMsg.isInThread()) {
    //       if (!maybePrevMsg.threadMsgId) prevMsg = maybePrevMsg
    //     }

    //     if (prevMsg) break
    //   }

    //   msg.setPreviousMessage( prevMsg )
    // } )

    return sortedMsgs
  } )
  const updateMessage = message => setMessages( msgs => {
    const messageIndex = msgs.findIndex( msg => msg.id === message.id )

    if (messageIndex === -1) return msgs

    msgs[ messageIndex ] = message
    msgs[ messageIndex ].setPreviousMessage( msgs[ messageIndex - 1 ] )

    return [ ...msgs ]
  } )

  const loadMore = (fromNow = false) => {
    if (sState.downloading || sState.upEnd) return

    sState.downloading = true
    rc.readHistory({ from:fromNow ? null : messages[ 0 ]?.ts, count:ctx.messagesQueryCount }).then( payload => {
      sState.downloading = false

      if (!payload || channelId != sState.channelId) return

      const { messages } = payload

      if (!messages.length) {
        sState.upEnd = true
        return
      }

      setSortedMessages( messages )
    } )
  }


  useEffect( () => {
    /** @type {MessageEventHandler} */
    const handler = (messageObj, update) => {
      if (update && messages.find( m => m.id === messageObj.id )) {
        return updateMessage( messageObj )
      }

      sState.fixedScroll = false
      setSortedMessages([ messageObj ])
    }
    const sState = staticStateRef.current

    rc.addEventListener( `message`, handler )

    return () => {
      console.log( 123 )
      rc.removeEventListener( `message`, handler )
    }
  }, [ channelId ] )

  useEffect( () => {
    sState.upEnd = false
    sState.downEnd = false
    sState.fixedScroll = true
    sState.downloading = false
    sState.channelId = channelId
    ctx.setActiveThread( null )
    setMessages([])
    loadMore( true )
  }, [ channelId ] )

  // useEffect( () => {
  //   messages.forEach( m => ctx.threads.includes( m.id ) && m.deduce)
  // }, [ ctx.threads.join() ] )

  useLayoutEffect( () => {
    sState.fixedScroll = true
    setReload( n => n + 1 )
  }, [ sState.fixedScroll ] )


  return (
    <List className={classess.messagesList} loading={sState.downloading} loaderTrigger={loadMore} hasNextPage={!sState.upEnd} fixedScroll={sState.fixedScroll}>
      {messages.map( m => <ListItem key={m.id}><Message rcMsg={m} /></ListItem> )}
    </List>
  )
}

/**
 * @typedef {object} DateParts
 * @property {string} year
 * @property {string} month
 * @property {string} day
 * @property {string} hour
 * @property {string} minute
 * @property {string} second
 * @property {string} milis
 */

export default function formatDate( date = new Date(), format = `YYYY.MM.DD hh:mm` ) {
  const dateParts = getDateparts( date )

  return `${format}`
    .replace( /YYYY/, dateParts.year )
    .replace( /YY/, dateParts.year.slice( -2 ) )
    .replace( /MM/, dateParts.month )
    .replace( /DD/, dateParts.day )
    .replace( /hh/, dateParts.hour )
    .replace( /mm/, dateParts.minute )
    .replace( /ss/, dateParts.second )
    .replace( /ms/, dateParts.milis.toString().slice( -4 ) )
}

/** @returns {DateParts} */
export function getDateparts( date = new Date() ) {
  const time = typeof date === `number` ? date : new Date(date).getTime()
  const options = {
    year: `numeric`,
    month: `2-digit`,
    day: `2-digit`,
    hour: `2-digit`,
    minute: `2-digit`,
    second: `2-digit`,
  }

  const parts = new Intl.DateTimeFormat( `pl`, options ).formatToParts( time )
  const partsDescribedInOptions = parts.filter( ({ type }) => type in options )
  const processedParts = Object.fromEntries( partsDescribedInOptions.map( ({ type, value }) => [ type, value ] ) )

  return { ...processedParts, milis:time.toString().slice( -4 ) }
}

export function isValidDatePattern( pattern ) {
  return !isNaN( Date.parse( pattern ) )
}

import React, { useEffect, useRef, useState } from "react"
import Twemoji from "react-twemoji"
import * as classess from "./chat.module.css"

const Picker = React.lazy( async() => typeof window === `undefined` ? null : import( `emoji-picker-react`) )

export default function EmojiPicker({ className, style, onChoose, onClickOutside }) {
  const ref = useRef( null )

  useEffect( () => {
    const handleClickOutside = ({ target }) => {
      const node = ref.current

      if (node && !node.contains( target )) {
        onClickOutside?.()
      }
    }

    document.addEventListener( `mousedown`, handleClickOutside )

    return () => document.removeEventListener( `mousedown`, handleClickOutside )
  }, [ ref ] )

  return (
    <div className={className} style={style} ref={ref}>
      <React.Suspense fallback="">
        <Twemoji options={{ className:classess.emoji }}>
          <Picker
            onEmojiClick={(_, emojiData) => onChoose?.( emojiData )}
            // disableAutoFocus={true}
            // groupNames={{ smileys_people:`PEOPLE` }}
            native
            pickerStyle={{}}
          />
        </Twemoji>
      </React.Suspense>
    </div>
  )
}

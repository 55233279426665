import { useEffect, useMemo, useState } from "react"
import RCMessage from "./RCMessage"

// TODO: https://developer.rocket.chat/reference/api/rest-api/endpoints/team-collaboration-endpoints/channels-endpoints/messages

export const API_ENDPOINT = `/api/v1`

export const cachingFetch = async(url, init) => {
  const data = await fetch( url, init ).then( r => r.json() ).catch( () => null )

  if (data) sessionStorage.setItem( url, JSON.stringify( data ) )
  else return JSON.parse( sessionStorage.getItem( url ) )

  return data
}

export default function useRocketChatHttp({ url, authToken, userId, channelId, registerThread }) {
  const [ userInfo, setUserInfo ] = useState( null )
  const http = useMemo( () => {
    const urlWithoutSlash = url.endsWith( `/` ) ? url.slice( 0, -1 ) : url
    const apiUrl = urlWithoutSlash + API_ENDPOINT
    const abstractedFetch = (endpoint, init) => cachingFetch( apiUrl + endpoint, init )
    const payloadToQuery = payload => !payload ? `` : `?` + Object.entries( payload )
      .map( ([ key, value ]) => value ? `${key}=${value}` : null )
      .filter( Boolean )
      .join( `&` )

    const authheaders = {
      "X-Auth-Token": authToken,
      "X-User-Id": userId,
    }
    const authJsonheaders = {
      ...authheaders,
      "Content-Type": `application/json`,
    }

    return {
      get: (endpoint, payload) => abstractedFetch( endpoint + payloadToQuery( payload ), { method:`GET`, headers:authJsonheaders } ),
      post: (endpoint, payload, isFormData) => {
        let headers = authJsonheaders
        let body

        if (!isFormData) body = JSON.stringify( payload )
        else {
          const formData = new FormData()

          Object.entries( payload ).filter( ([ , v ]) => !!v ).forEach( ([ k, v ]) => formData.append( k, v ) )

          headers = authheaders
          body = formData
        }

        return abstractedFetch( endpoint, { method:`POST`, headers, body } )
      },
    }
  }, [ url, authToken, userId ] )

  useEffect( () => {
    http.get( `/users.info`, { userId } ).then( r => {
      if (r && `user` in r) setUserInfo( r.user )
      else console.warn( r )
    } )
  }, [ userId ] )

  return {
    userInfo,
    methods: http,

    // getChannelHistory() {
    async readHistory( { from, to, count = 20 } = {} ) {
      if (!channelId) return null

      // const hour = 1000 * 60 * 60
      // const day = hour * 24

      if (typeof from === `number`) from = new Date( from ).toISOString()
      else if (from instanceof Date) from = from.toISOString()

      if (typeof to === `number`) to = new Date( to ).toISOString()
      else if (to instanceof Date) to = to.toISOString()

      const result = await http.get( `/channels.history`, {
        roomId: channelId,
        count,
        latest: from,
        oldest: to,
      } )

      if (!result) return {
        messages: [],
        firstUnread: null,
      }

      return {
        messages: result.messages.filter( m => !m.t ).map( m => {
          const msg = new RCMessage( m )

          if (msg.threadMsgId) registerThread( msg.id )

          return msg
        } ),
        firstUnread: result.firstUnread ? new RCMessage( result.firstUnread ) : null,
      }
    },
    async readThreadHistory( { threadMessageId } = {} ) {
      // const hour = 1000 * 60 * 60
      // const day = hour * 24

      const result = await http.get( `/chat.getThreadMessages`, {
        tmid: threadMessageId,
      } )

      if (!result) return {
        messages: [],
        firstUnread: null,
      }

      return {
        messages: result.messages.map( m => {
          const msg = new RCMessage( m )

          if (msg.threadMsgId) registerThread( msg.id )

          return msg
        } ),
        firstUnread: result.firstUnread ? new RCMessage( result.firstUnread ) : null,
      }
    },
    async reactToMessage( emoji, messageId, shouldReact = undefined ) {
      const result = await http.post( `/chat.react`, {
        emoji: /:.+:/.test( emoji ) ? emoji.slice( 1, -1 ) : emoji,
        messageId,
        shouldReact,
      } )
    },

    /**
     * @param {string | { threadMsgId:string }} optionsOrContent
     * @param {string} content
     */
    async sendMessage( optionsOrContent, content ) {
      if (!channelId) return null

      const fixedContent = typeof optionsOrContent === `string` ? optionsOrContent : content
      const fixedOptions = optionsOrContent && typeof optionsOrContent === `object` ? optionsOrContent : {}

      console.log({ optionsOrContent })
      const result =  fixedOptions.attachments?.length
        ? await http.post( `/rooms.upload/${channelId}`, {
          file: fixedOptions.attachments[ 0 ],
          msg: fixedContent,
          // description: `A file`,
          tmid: fixedOptions.threadMsgId,
        }, true )
        : await http.post( `/chat.sendMessage`, {
          message: {
            rid: channelId,
            msg: fixedContent,
            tmid: fixedOptions.threadMsgId,
          },
        } )

      return result
    },

    async getUserAvatar( username ) {
      const result = await http.get( `/users.getAvatar`, { username } )

      console.log({ result })
    },

    async getUserChannels() {
      const result = await http.get( `/channels.list.joined` )

      return result?.channels ?? []
    },
  }
}

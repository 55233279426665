import { createContext } from "react"

/** @typedef {import("React")} React */
/** @typedef {import("./logic/useRocketChat.js").UseRocketChatValue} UseRocketChatValue */
/**
 * @typedef {object} ChatContextValue
 * @property {UseRocketChatValue} rc
 * @property {string} channelId
 * @property {(namespace:string key:string) => unknown} useCachedData
 * @property {number} endTop
 * @property {number} endBottom
 * @property {number} messagesOffset
 * @property {number} messagesQueryCount
 * @property {number} loadedMessagesCount
 * @property {unknown} msgToResponse
 * @property {Map<{ id:string }>} threads
 * @property {(thread:unknown) => void} setMsgToResponse
 * @property {unknown} activeThread
 * @property {(thread:unknown) => void} setActiveThread
 */

/** @type {React.Context<ChatContextValue>} */
export const ctx = createContext({
  endTop: false,
  endBottom: true,

  messagesOffset: 0,
  messagesQueryCount: 5,
  messagesCount: 0,
  cachedUsers: new Map(),

  msgToResponse: null,
  setMsgToResponse: () => {},

  activeThread: null,
  setActiveThread: () => {},
})

export default ctx

import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export default function useWs({ id, url, onCreate, onOpen, onMessage, onClose, onError, restartConditions }) {
  const canIBeRestartedViaDeps = useRef( false )
  const [ countOfResets, setResetCount ] = useState( 0 )

  /** @type {() => WebSocket & { id:unknown, onCreate:() => void, restart:() => void }} */
  const getWs = useCallback( () => {
    const ws = typeof window === `undefined` ? {} : new WebSocket( url )

    ws.id = id
    ws.onCreate = onCreate
    ws.restart = () => {
      ws.close()
      setResetCount( c => c + 1 )
    }

    ws.onCreate?.()

    return ws
  }, [ onCreate ] )

  const ws = useMemo( () => getWs(), [ countOfResets ] )


  useEffect( () => {
    ws.addEventListener( `open`, onOpen )
    ws.addEventListener( `message`, onMessage )
    ws.addEventListener( `close`, onClose )
    ws.addEventListener( `error`, onError )

    return () => ws.close()
  }, [ ws ] )

  useEffect( () => {
    if (!canIBeRestartedViaDeps.current) return canIBeRestartedViaDeps.current = true

    ws.restart()
  }, restartConditions )


  return ws
}

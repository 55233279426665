import React, { useContext, useEffect, useRef, useState } from "react"
import ChatContext from "./ChatContext"
import Input from "./Input"

import Icon from "./Icon"
import * as classess from "./chat.module.css"
import List, { ListItem } from "../list/List"
import Message from "./Message"

/** @typedef {import("./logic/RCMessage").default} RCMessage */

/** @param {{ thread:RCMessage rc:import("./logic/useRocketChat").UseRocketChatValue }} param0 */
export default function Thread({ thread, className, name, rc }) {
  const ctx = useContext( ChatContext )
  const staticStateRef = useRef({
    fixedScroll: true,
    downloading: false,
    upEnd: false,
    downEnd: true,
  })
  const sState = staticStateRef.current

  const [ messages, setMessages ] = useState([])

  const closeThread = () => ctx.setActiveThread( null )
  const loadMore = () => {}

  const send = (value, attachments) => {
    rc.sendMessage( { threadMsgId:thread.id, attachments }, value )
  }

  const setSortedMessages = (messages, shouldReset = false) => setMessages( msgs => {
    /** @type {RCMessage[]} */
    const sortedMsgs = shouldReset ? [ ...messages ] : [ ...msgs, ...messages ]
      .filter( ({ id }, i, arr) => arr.findIndex( m => m.id === id ) === i )
      .sort( (a, b) => a.ts - b.ts )

    sortedMsgs.forEach( (msg, i, arr) => {
      msg.setPreviousMessage( arr[ i - 1 ] )
    } )

    return sortedMsgs
  } )

  const updateMessage = message => setMessages( msgs => {
    const messageIndex = msgs.findIndex( msg => msg.id === message.id )

    if (messageIndex === -1) return msgs

    msgs[ messageIndex ] = message
    msgs[ messageIndex ].setPreviousMessage( msgs[ messageIndex - 1 ] )

    return [ ...msgs ]
  } )


  useEffect( () => {
    if (!thread) return

    rc.readThreadHistory({ threadMessageId:thread.id }).then( ({ messages }) => setSortedMessages( [ thread, ...messages ], true ) )
  }, [ thread?.id ] )

  useEffect( () => {
    /** @type {MessageEventHandler} */
    const handler = (messageObj, update) => {
      if (messageObj.threadMsgId !== thread.id) return
      if (update && messages.some( m => m.id === messageObj.id )) {
        return updateMessage( messageObj )
      }

      sState.fixedScroll = false
      setSortedMessages([ messageObj ])
    }
    const sState = staticStateRef.current

    rc.addEventListener( `message`, handler )

    loadMore()

    return () => rc.removeEventListener( `message`, handler )
  }, [ messages.length ] )


  return (
    <article className={className}>
      <header className={classess.threadHeader}>
        <h2 className={classess.threadHeaderTitle}>Wątek</h2>

        <Icon className={classess.closeBtn} onClick={closeThread} name="close" />
      </header>

      <List className={classess.threadMessages} loading={true} loaderTrigger={loadMore} hasNextPage={false} fixedScroll={sState.fixedScroll}>
        {messages.map( (m, i) => <ListItem key={m.id}><Message isInThread isSlim={i === 0 ? false : null} rcMsg={m} /></ListItem> )}
      </List>

      <Input className={classess.threadInput} name={name} rc={rc} onSubmit={send} />
    </article>
  )
}

import RCMessage from "./RCMessage"
import { storedIDs } from "./useRocketChatWS"

export const onMessage = (data, eventsHandlers) => {
  const collection = storedIDs.has( data.id ) ? storedIDs.get( data.id ) : data.collection

  if (collection === `stream-room-messages`) {
    const messages = data.result
      ? (Array.isArray( data.result ) ? data.result : [ data.result ])
      : data.fields.args

    messages.forEach( m => eventsHandlers.onMessage.forEach( h => h( new RCMessage( m ), data.msg === `changed` ) ) )
  }
}

export const data = ({ send, authSend, close, addEventListener, removeEventListener }) => ({
  send,
  authSend,
  addEventListener,
  removeEventListener,
  close,
})

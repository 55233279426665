import React, { useContext, useState } from "react"
import Twemoji from "react-twemoji"
// import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react"
import { getDateparts } from "../../functions/formatDate"
import ChatContext from "./ChatContext"
import * as classess from "./chat.module.css"
import Icon from "./Icon"
import { RCAtachment } from "./logic/RCMessage"
import EmojiPicker from "./EmojiPicker"

/** @typedef {import("./logic/RCMessage").default} RCMessage */

/** @param {{ rcMsg:RCMessage }} param0 */
export default function Message({ isInThread, isSlim, rcMsg }) {
  const [ isEmojiPickerVisible, setEmojiPickerVisibility ] = useState( false )
  const [ activeImage, setActiveImage ] = useState( null )

  const ctx = useContext( ChatContext )
  const today = getDateparts( new Date() )
  const date = getDateparts( new Date( rcMsg.ts ) )
  const isDateToday = today.year === date.year && today.month === date.month && today.day === date.day
  const shouldConnectMessages = isSlim == null ? rcMsg.canBeMergedWithPrevious() : isSlim
  const dateTime = isDateToday
    ? `Dziś o ${date.hour}:${date.minute}`
    : `Dnia ${date.day}.${date.month} o ${date.hour}:${date.minute}`
  const time = `${date.hour}:${date.minute}`

  const fullTime = `${date.year}.${date.month}.${date.day} ${date.hour}:${date.minute}`
  const onClickResponse = () => ctx.setMsgToResponse( rcMsg )
  const onClickThread = () => ctx.setActiveThread( rcMsg )
  const reactToMessageWithExistingReaction = (emojiName, msgId) => ctx.rc.reactToMessage( emojiName, msgId )
  // const reactToMessage = (emojiName, msgId) => ctx.rc.reactToMessage( emojiName, msgId )
  const onEmojiChoose = ({ names }, msgId) => {
    ctx.rc.reactToMessage( names.at( -1 ), msgId )
    setEmojiPickerVisibility( false )
  }

  const urlsWithPreview = [
    ...rcMsg.urls.filter( l => l.isImage || l.isVideo ),
    ...rcMsg.attachments.filter( a => a.type === RCAtachment.Type.IMAGE ).map( i => ({
      ...i,
      url: ctx.rc.httpUrl + i.src,
      isImage: true,
    }) ),
  ]
  const reply = rcMsg.attachments.find( a => a.type === RCAtachment.Type.MESSAGE )
  const files = rcMsg.attachments.filter( a => a.type === RCAtachment.Type.FILE )
    .map( f => ({ ...f, url:ctx.rc.httpUrl + f.src }) )

  // const authorAvatar = ctx.useCachedData( `users`, rcMsg.author.lastname )?.avatarUrls

  return !isInThread && !rcMsg.isVisibleInMainThread() ? null : (
    <article className={`${classess.messageWrapper} ${isEmojiPickerVisible ? classess.isActive : ``} ${shouldConnectMessages ? classess.isSmall : ``}`}>
      {
        reply && (
          <section className={classess.messageReply}>
            {/* Odpowiedź do  */}
            @<b className="is-bold">{reply.author}</b>
            {`: ${reply.messageContent}`}
          </section>
        )
      }
      <section className={classess.message}>
        <section className={`${classess.messageAside} ${shouldConnectMessages ? classess.isMerged : ``}`}>
          {
            shouldConnectMessages ? (
              <time className={classess.messageTime} title={fullTime}>{time}</time>
            ) : (
              <img className={classess.messageAuthorAvatar} src={`${ctx.rc.httpUrl}/avatar/${rcMsg.author.lastname}`} alt="avatar" />
            )
          }
        </section>

        <section>
          {
            !shouldConnectMessages && (
              <div className={classess.messageData}>
                {/* <span className={classess.messageAuthor}>{rcMsg.author.firstname} {rcMsg.author.lastname}</span> */}
                <span className={classess.messageAuthor}>{rcMsg.author.lastname}</span>
                <time className={classess.messageTime} title={fullTime}>{dateTime}</time>
                {
                  rcMsg.isThreadRoot() && !isInThread && (
                    <button className={classess.messageShowThread} onClick={onClickThread}>
                      Pokaż wątek ({rcMsg.threadRepliesCount} odpowiedzi)
                    </button>
                  )
                }
              </div>
            )
          }

          <div className={classess.messagePayload}>
            {/* <p className={classess.messageDebug}>
              <span>id: {rcMsg.id}</span>
              <br />
              <span>prev: {rcMsg.previousMessage?.id}</span>
            </p> */}

            <Twemoji options={{ className:classess.emoji }}>
              <div className={classess.messageContent} dangerouslySetInnerHTML={{ __html:rcMsg.contentHtml }} />
            </Twemoji>

            {
              !!urlsWithPreview.length && (
                <div className={classess.messageUrls}>
                  {
                    urlsWithPreview.map( l => {
                      if (l.isImage) {
                        const handleClick = () => setActiveImage( url => url === l.url ? null : l.url )

                        return (
                          <button key={l.url} className={classess.messageAttachedImageWrapper} onClick={handleClick}>
                            <img className={classess.messageAttachedImage} src={l.url} />
                            <div className={classess.messageAttachedImageOrginalWrapper} style={{ display:(l.url === activeImage ? `flex` : `none`) }}>
                              <img className={classess.messageAttachedImageOrginal} src={l.url} />
                              <a className={classess.messageAttachedImageOpenOrginal} href={l.url}>Otwórz oryginał</a>
                            </div>
                          </button>
                        )
                      }

                      if (l.isVideo) {
                        return <iframe key={l.url} src={l.url} />
                      }
                    } )
                  }
                </div>
              )
            }
            {
              files.length > 0 && (
                <div className={classess.messageFileAttachments}>
                  {
                    files.map( (f, i) => (
                      <a key={i} href={f.url} className={classess.messageFileAttachment}>{f.name}</a>
                    ) )
                  }
                </div>
              )
            }
          </div>

          {
            !!rcMsg.reactions.length && (
              <Twemoji options={{ className:classess.messageReactionEmoji }}>
                <ol className={classess.messageReactions}>
                  {
                    rcMsg.reactions.map( (r, i) => (
                      <li key={i}>
                        <button
                          className={`${classess.messageReaction} ${r.users.some( u => u.lastname === ctx.rc.userInfo?.username ) ? classess.isMy : ``}`}
                          onClick={() => reactToMessageWithExistingReaction( r.name, rcMsg.id )}
                        >
                          <span className={classess.messageReactionEmoji}>{r.nativeEmoji}</span>
                          <span className={classess.messageReactionCount}>{r.count}</span>
                          <div className={classess.messageReactionUsersWrapper}>
                            <div className={classess.messageReactionUsers}>
                              {r.users.map( u => <span key={u.lastname} className={classess.messageReactionUser}>{u.lastname}</span> )}
                            </div>
                          </div>
                        </button>
                      </li>
                    ) )
                  }
                </ol>
              </Twemoji>
            )
          }
        </section>
      </section>

      <ol className={classess.menu}>
        <li className={classess.menuItem}>
          <Icon onClick={() => setEmojiPickerVisibility( bool => !bool )} name="emoji" title="Zareaguj" />

          {
            isEmojiPickerVisible && (
              <EmojiPicker
                className={classess.messageEmojiPicker}
                onChoose={emojiData => onEmojiChoose( emojiData, rcMsg.id )}
                onClickOutside={() => setEmojiPickerVisibility( false )}
              />
            )
          }
        </li>

        <li className={classess.menuItem}>
          <Icon onClick={onClickResponse} name="response" title="Odpowiedz" />
        </li>

        {
          !isInThread && (
            <li className={classess.menuItem}>
              <Icon onClick={onClickThread} name="message" title="Stwórz wątek" />
            </li>
          )
        }
      </ol>
    </article>
  )
}

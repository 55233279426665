import React from "react"

import threadIcon from "./images/thread.svg"
import responseIcon from "./images/response.svg"
import closeIcon from "./images/close.svg"
import emojiIcon from "./images/emoji.svg"
import sendIcon from "./images/send.svg"
import attachIcon from "./images/attach.svg"

import * as classess from "./chat.module.css"

export default function Icon({ className, onClick, name, title }) {
  const src = (() => {
    switch (name) {
      case `message`: return threadIcon
      case `response`: return responseIcon
      case `close`: return closeIcon
      case `emoji`: return emojiIcon
      case `send`: return sendIcon
      case `attach`: return attachIcon
    }
  })()

  const iconBody = (
    <>
      <img className={classess.icon} src={src} />
      {title && <span className={classess.iconTitle}>{title}</span>}
    </>
  )

  const cn = `${classess.iconWrapper} ${className}`

  return onClick
    ? <button className={cn} onClick={onClick}>{iconBody}</button>
    : <div className={cn}>{iconBody}</div>
}

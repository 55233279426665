import { getDateparts } from "../../../functions/formatDate"
import * as EmojiMart from "emoji-mart"

const getRCDate = obj => typeof obj === `string` ? new Date( obj ).getTime() : (obj?.$date ?? null)

export class RCReaction {
  constructor( emojiObj ) {
    this.name = emojiObj.name
    this.nativeEmoji = RCReaction.strEmojify( emojiObj.name )
    this.users = emojiObj.usernames.map( username => ({ lastname:username }) )
    this.count = this.users.length
  }

  static parseObj( obj ) {
    if (!obj) return []

    return Object.entries( obj ).map( ([ name, obj ]) => new RCReaction({ ...obj, name }) )
  }

  static strEmojify = str => {
    const isEmojiStr = /^:([^ ]+?):$/.test( str )

    if (isEmojiStr) {
      const emojiName = str.slice( 1, -1 )
      const emojiObj = EmojiMart.emojiIndex.emojis[ emojiName ]

      if (!emojiObj) return str
      if (`id` in emojiObj) return emojiObj.native
      else return emojiObj[ 1 ].native
    }

    return str
  }
}

export class RCLink {
  constructor( urlObj ) {
    this.url = urlObj.url

    /** @type {Record<string,string>} */
    this.headers = urlObj.headers

    /** @type {Record<string,string>} */
    this.meta = urlObj.meta
    this.isImage = /\.(?:png|jpg|jpeg|gif)$/.test( urlObj.url )
    this.isVideo = urlObj.meta?.oembedType === `video`

    if (urlObj.meta?.oembedProviderName === `YouTube`) {
      const videoId = (this.url.match( /v=([^&]+)/ ) ?? this.url.match( /be\/([^&]+)/ ))[ 1 ]
      this.url = `https://www.youtube.com/embed/${videoId}`
    }
  }
}

export class RCAtachment {
  static Type = {
    MESSAGE: `MESSAGE`,
    FILE: `FILE`,
    IMAGE: `IMAGE`,
  }

  constructor( obj ) {
    if (RCAtachment.isMessage( obj )) {
      this.type = RCAtachment.Type.MESSAGE
      this.avatarUrl = obj.author_icon
      this.author = obj.author_name
      this.mesageLink = obj.message_link
      this.messageContent = obj.text
      this.ts = getRCDate( obj.ts )
    } else if (RCAtachment.isImage( obj )) {
      this.type = RCAtachment.Type.IMAGE
      this.src = obj.title_link
      this.name = obj.title
      this.width = obj.image_dimensions.width
      this.height = obj.image_dimensions.height
    } else if (RCAtachment.isFile( obj )) {
      this.type = RCAtachment.Type.FILE
      this.src = obj.title_link
      this.name = obj.title
    }
  }

  static isMessage( obj ) {
    return typeof obj === `object`
      && Array.isArray( obj.attachments )
      && typeof obj.author_icon === `string`
      && typeof obj.author_name === `string`
      && typeof obj.message_link === `string`
      && typeof obj.text === `string`
      && typeof obj.ts === `string`
  }

  static isImage( obj ) {
    return RCAtachment.isFile( obj )
      && `image_type` in obj
  }

  static isFile( obj ) {
    return typeof obj === `object`
      && obj.type === `file`
  }
}

export default class RCMessage {
  static tags = [
    { reg: /\[ \]\(.*?\)/g,
      stopSearch: true,
      replacer: () => ``,
    },
    { reg: /```(.+?)```/gs,
      stopSearch: true,
      replacer: (m, s) => `${s ? `\`\`\`` : ``}<code class="is-big_code">${m.slice( 3, -3 ).trim()}</code>${s ? `\`\`\`` : ``}`,
    },
    { reg: /`(.+?)`/gs,
      stopSearch: true,
      replacer: (m, s) => `${s ? `\`` : ``}<code class="is-code">${m.slice( 1, -1 )}</code>${s ? `\`` : ``}`,
    },
    { reg: /(?:^|\b)https?:\/\/\w+\.\S+\b/g,
      stopSearch: true,
      replacer: m => `<a href="${m}" target="_blank">${m.length > 75 ? m.slice( 0, 30 ) + `...` + m.slice( -15 ) : m}</a>`,
    },
    { reg:/\*(.+?)\*/gs,  replacer:(m, s) => `${s ? `*` : ``}<b class="is-bold">${m.slice( 1, -1 )}</b>${s ? `*` : ``}` },
    { reg:/_(.+?)_/gs,    replacer:(m, s) => `${s ? `_` : ``}<i class="is-italic">${m.slice( 1, -1 )}</i>${s ? `_` : ``}` },
    { reg:/~(.+?)~/gs,    replacer:(m, s) => `${s ? `~` : ``}<s class="is-strike">${m.slice( 1, -1 )}</s>${s ? `~` : ``}` },
    { reg:/:([^ ]+?):/g,  replacer:(m, s) => RCReaction.strEmojify( m ) },
  ]

  /** @type {RCMessage | null} */
  previousMessage = null

  constructor( rcMessageobj ) {
    this.id = rcMessageobj._id
    this.channelId = rcMessageobj.rid
    this.threadMsgId = rcMessageobj.tmid
    this.threadRepliesCount = rcMessageobj.tcount
    this.threadLastUpdate = getRCDate( rcMessageobj.tlm )
    this.ts = getRCDate( rcMessageobj.ts )
    this.content = rcMessageobj.msg
    this.contentHtml = RCMessage.stringToJsx( rcMessageobj.msg )
    this.attachments = rcMessageobj.attachments?.map( a => new RCAtachment( a ) ) ?? []
    this.reactions = RCReaction.parseObj( rcMessageobj.reactions )

    /** @type {RCLink[]} */
    this.urls = rcMessageobj.urls?.map( urlObj => new RCLink( urlObj ) ) ?? []
    this.author = {
      firstname: rcMessageobj.u.name,
      lastname: rcMessageobj.u.username,
    }
  }


  /** @param {Message} message */
  setPreviousMessage( message ) {
    if (message instanceof RCMessage) this.previousMessage = message
  }


  havePreviousMessageSameAuthor() {
    if (!this.previousMessage) return false

    const prevAuthor = this.previousMessage.author
    const author = this.author

    return prevAuthor.firstname === author.firstname && prevAuthor.lastname === author.lastname
  }


  canBeMergedWithPrevious() {
    if (!this.havePreviousMessageSameAuthor()) return false
    if (this.attachments.some( a => a.type === RCAtachment.Type.MESSAGE )) return false
    if (this.isThreadRoot()) return false

    const mergeTime = 1000 * 60 * 5
    const prevDate = getDateparts( this.previousMessage.ts )
    const date = getDateparts( this.ts )

    return this.ts - this.previousMessage.ts < mergeTime && prevDate.year === date.year && prevDate.month === date.month && prevDate.day === date.day
  }


  isVisibleInMainThread() {
    return !this.threadMsgId
  }


  isThreadRoot() {
    return this.threadLastUpdate !== null
  }

  isInThread() {
    return !!this.threadMsgId
  }


  static stringToJsx( string, saveChars = false ) {
    const escapedStr = string
      .replace( /&/g, `&amp;` )
      .replace( /</g, `&lt;` )
      .replace( />/g, `&gt;` )
      .replace( /"/g, `&quot;` )
      .replace( /'/g, `&#039;` )

    const replacer = str => {
      let replaced = false
      let replacedStr = str

      for (const { reg, stopSearch = false, replacer:replacerLike } of RCMessage.tags) {
        const replacer = typeof replacerLike === `function` ? replacerLike : () => replacerLike

        replacedStr = replacedStr.replace( reg, m => {
          replaced = true
          return replacer( m, saveChars )
        } )

        if (stopSearch && replaced) break
      }

      return { string:replacedStr, replaced }
    }

    let replacedString = replacer( escapedStr ).string

    return replacedString
  }
}

import * as React from "react"
import Icon from "./Icon"
import * as classess from "./chat.module.css"
import ChatContext from "./ChatContext"
import EmojiPicker from "./EmojiPicker"

/** @typedef {(e:KeyboardEvent) => any} SubmitListener */
/** @typedef {(e:ClipboardEvent) => any} PasteListener */

export default function Input({ className, name, onSubmit, response }) {
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const ref = React.useRef()
  const id = `input-${name}`
  const ctx = React.useContext( ChatContext )

  const [ isEmojiPickerVisible, setEmojiPickerVisibility ] = React.useState( false )
  const [ attachments, setAttachments ] = React.useState([])
  const fileInputRef = React.useRef( null )

  const submit = () => {
    const input = ref.current
    const value = input.innerText.trim()

    if (!value) return

    input.innerHTML = ``

    const maybeFalse = onSubmit?.( value, attachments.map( a => a.payload ) )

    if (maybeFalse === false) return

    input.innerHTML = ``
    setAttachments([])
  }

  /** @type {SubmitListener} */
  const submitHandler = React.useCallback( e => {
    if (e.key !== `Enter` || e.shiftKey) return

    e.preventDefault()

    submit()
  }, [ onSubmit, attachments.length ] )

  /** @type {PasteListener} */
  const pasteHandler = React.useCallback( e => {
    e.preventDefault()

    const { files, types } = e.clipboardData


    if (types.includes( `Files` )) {
      const file = files[ 0 ]

      onFileChoose([ file ])
    } else if (types.includes( `text/plain` )) {
      const text = e.clipboardData.getData( `text` )

      document.execCommand( `insertHTML`, false, text )
    }
  }, [ onSubmit, attachments.length ] )

  const closeTooltip = () => ctx.setMsgToResponse( null )
  const onEmojiChoose = React.useCallback( ({ emoji }) => {
    ref.current.innerText += emoji
    setEmojiPickerVisibility( false )
  }, [ ref.current ] )

  const onFileChoose = files => {
    const attachment = {
      id: Date.now().toString( 32 ),
      payload: files[ 0 ],
      url: URL.createObjectURL( files[ 0 ] ),
      type: files[ 0 ].type,
    }

    // setAttachments( a => [ ...a, attachment ] )
    setAttachments([ attachment ])
  }

  const removeAttachment = id => {
    setAttachments( attachments => attachments.filter( a => a.id != id ) )
  }


  React.useEffect( () => {
    const input = ref.current

    if (!input) return

    const removableSubmitHandler = e => submitHandler( e )
    const removablePasteHandler = e => pasteHandler( e )

    input.addEventListener( `keydown`, removableSubmitHandler )
    input.addEventListener( `paste`, removablePasteHandler )

    return () => {
      input.removeEventListener( `keydown`, removableSubmitHandler )
      input.removeEventListener( `paste`, removablePasteHandler )
    }
  }, [ submitHandler ] )


  return (
    <article className={`${classess.inputWrapper} ${className ?? ``}`}>
      {
        attachments.length > 0 && (
          <section className={classess.inputAttachments}>
            {
              attachments.map( a => (
                <div key={a.id} className={classess.inputAttachment}>
                  <div className={classess.inputAttachmentData}>
                    <span className={classess.inputAttachmentName}>{a.payload.name}</span>
                    {a.type.startsWith( `image` ) && <img className={classess.inputAttachmentPreview} src={a.url} />}
                  </div>

                  <ol className={classess.menu}>
                    <li className={classess.menuItem}>
                      <Icon onClick={() => removeAttachment( a.id )} name="close" />
                    </li>
                  </ol>
                </div>
              ) )
            }
          </section>
        )
      }

      {
        response && (
          <section className={classess.inputTooltip}>
            <div className={classess.iconTooltipContent}>{response}</div>
            <Icon className={classess.inputTooltipIcon} onClick={closeTooltip} name="close" />
          </section>
        )
      }

      <section className={classess.input}>
        {
          isEmojiPickerVisible && (
            <EmojiPicker
              className={classess.emojiPicker}
              onChoose={emojiData => onEmojiChoose( emojiData )}
              onClickOutside={() => setEmojiPickerVisibility( false )}
            />
          )
        }

        <Icon className={`${classess.inputIcon}`} onClick={() => fileInputRef.current?.click()} name="attach" />
        <Icon className={`${classess.inputIcon}`} onClick={() => setEmojiPickerVisibility( v => !v )} name="emoji" />

        <div id={id} className={classess.inputTextarea} contentEditable ref={ref} placeholder="Napisz na czacie..." />
        <input type="file" ref={fileInputRef} style={{ display:`none` }} onChange={e => onFileChoose( Array.from( e.target.files ) )} />

        <Icon className={`${classess.inputIcon}`} onClick={submit} name="send" />
      </section>
    </article>
  )
}

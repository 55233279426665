import React, { forwardRef, useCallback, useLayoutEffect, useMemo, useRef } from "react"
import * as classess from "./list.module.css"

export const ListItem = forwardRef( ({ children }, ref) => {
  return <li ref={ref}>{children}</li>
} )

export default function List({ children, className = ``, style, loaderTrigger:triggerLoading, loading, items = children, hasNextPage, error, onScroll, topMargin, fixedScroll }) {
  /** @type {React.MutableRefObject<HTMLDivElement>*/
  const wrapperRef = useRef( null )
  const lastScrollHeight = useRef( 0 )
  const lastScrollDistanceToBottomRef = useRef( 0 )
  const establishedItems = useMemo( () => items, [ items ] )

  const handleRootScroll = useCallback( () => {
    const wrapper = wrapperRef.current

    if (!wrapper) return

    const scrollBottom = Math.abs( wrapper.scrollTop ) // : wrapper.scrollHeight - scrollTop

    lastScrollDistanceToBottomRef.current = scrollBottom

    if (!onScroll) return

    onScroll({
      scrollBottom,
      scrollTop: wrapper.scrollHeight + wrapper.scrollTop - wrapper.clientHeight,
      scrollHeight: wrapper.scrollHeight,
      clientHeight: wrapper.clientHeight,
    })
  }, [ onScroll ] )

  const observedItemRefHandler = useCallback( item => {
    if (!item) return

    const handleIntersects = entries => {
      if (error || loading || !hasNextPage) return
      if (entries[ 0 ].intersectionRatio === 1) triggerLoading()
    }

    const observer = new IntersectionObserver( handleIntersects, {
      root: wrapperRef.current,
      rootMargin: `${topMargin ?? 500}px 0px ${topMargin ?? 500}px 0px`,
    })

    observer.observe( item )
  }, [ wrapperRef, error, loading, hasNextPage, triggerLoading ] )


  useLayoutEffect( () => {
    const wrapper = wrapperRef.current
    const lastDistToBottom = lastScrollDistanceToBottomRef.current

    // console.log( lastDistToBottom < 1 && (lastScrollHeight.current != wrapper.scrollHeight || !wrapper), `=`, lastDistToBottom < 1, `&&`, lastScrollHeight.current != wrapper.scrollHeight, `||`, !wrapper )

    // if ((lastScrollHeight.current != wrapper.scrollHeight || !wrapper)) return
    if (lastDistToBottom > 1 || !wrapper) return

    lastScrollHeight.current = wrapper.scrollHeight
    // wrapper.scrollTop = -lastDistToBottom
  }, [ establishedItems.length, wrapperRef.current ] )


  return (
    <div className={`${classess.wrapper} ${className}`} style={style} ref={wrapperRef} onScroll={handleRootScroll}>
      <ol className={classess.list}>
        <li ref={observedItemRefHandler} data-observed-item="" />
        {establishedItems}
      </ol>
    </div>
  )
}

import useRocketChatHttp from "./useRocketChatHttp"
import useRocketChatWS from "./useRocketChatWS"
import * as wsConfig from "./wsConfig"

const DEBUG = true
export const WS_ORIGIN = `wss://chat3.instytutkryptografii.pl/websocket`
export const HTTP_ORIGIN = `https://chat3.instytutkryptografii.pl`

/** @typedef {{ data:Object<string,unknown>, resolve:(value: any) => void}} WaitingRequest */
/** @typedef {(message:Message) => void} MessageEventHandler */
/** @typedef {Message} Message */
/** @typedef {ReturnType<useRocketChat>} UseRocketChatValue */

export default function useRocketChat({ channelId, registerThread, userToken, userId }) {
  const http = useRocketChatHttp({
    authToken: userToken,
    userId: userId,
    channelId,
    registerThread,
    url: HTTP_ORIGIN,
    DEBUG,
  })

  const ws = useRocketChatWS({
    secret: userToken,
    url: WS_ORIGIN,
    channelId,
    onMessage: wsConfig.onMessage,
    DEBUG,
  })

  const configuredWs = wsConfig.data( ws )

  if (!http.userInfo) return null

  return {
    httpUrl: HTTP_ORIGIN,
    wsUrl: WS_ORIGIN,
    ...configuredWs,
    ...http,
  }
}

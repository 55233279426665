// extracted by mini-css-extract-plugin
export var component = "chat-module--component--Ib4ZD";
export var isWithThread = "chat-module--isWithThread--gFCU8";
export var emoji = "chat-module--emoji--1IMmo";
export var nav = "chat-module--nav--3x4AY";
export var navItems = "chat-module--navItems--2ZU5c";
export var navItem = "chat-module--navItem--K7Y6Y";
export var isActive = "chat-module--isActive--1Nnnj";
export var menu = "chat-module--menu--3jD2d";
export var menuItem = "chat-module--menuItem--P9izY";
export var channel = "chat-module--channel--33vm2";
export var channelThread = "chat-module--channelThread--1zrk4";
export var messageThread = "chat-module--messageThread--2x1V6";
export var threadHeader = "chat-module--threadHeader--3SVzJ";
export var threadHeaderTitle = "chat-module--threadHeaderTitle--2p42d";
export var threadMessages = "chat-module--threadMessages--1eUpS";
export var threadInput = "chat-module--threadInput--18WEY";
export var messagesList = "chat-module--messagesList--3rqmQ";
export var messageWrapper = "chat-module--messageWrapper--3JHDH";
export var isSmall = "chat-module--isSmall--3QGzv";
export var message = "chat-module--message--DSH_z";
export var messageDebug = "chat-module--messageDebug--10vam";
export var messageReply = "chat-module--messageReply--32QyA";
export var messageMenu = "chat-module--messageMenu--1NDlV";
export var messageMenuItem = "chat-module--messageMenuItem--30nrX";
export var messageAside = "chat-module--message-aside--35mdh";
export var isMerged = "chat-module--is-merged--2HGQC";
export var messageAuthorAvatar = "chat-module--message-author-avatar--3-xel";
export var messageData = "chat-module--messageData--43L8S";
export var messageAuthor = "chat-module--message-author--2oGeV";
export var messageTime = "chat-module--message-time--3h03o";
export var messageShowThread = "chat-module--messageShowThread--31sX3";
export var messagePayload = "chat-module--messagePayload--339UW";
export var messageContent = "chat-module--messageContent--3dEwB";
export var messageUrls = "chat-module--messageUrls--3Uq_i";
export var messageAttachedImageWrapper = "chat-module--messageAttachedImageWrapper--3TvJ4";
export var messageAttachedImage = "chat-module--messageAttachedImage--SpnUI";
export var messageAttachedImageOrginalWrapper = "chat-module--messageAttachedImageOrginalWrapper--1EMti";
export var messageAttachedImageOpenOrginal = "chat-module--messageAttachedImageOpenOrginal--1D7mI";
export var messageAttachedImageOrginal = "chat-module--messageAttachedImageOrginal--1u4Q4";
export var messageFileAttachments = "chat-module--messageFileAttachments--3KBWs";
export var messageFileAttachment = "chat-module--messageFileAttachment--BLRBB";
export var messageReactions = "chat-module--messageReactions--3VCia";
export var messageReaction = "chat-module--messageReaction--2b382";
export var isMy = "chat-module--isMy--3ytkW";
export var messageReactionUsersWrapper = "chat-module--messageReactionUsersWrapper--5kBnw";
export var messageReactionEmoji = "chat-module--messageReactionEmoji--1vvBh";
export var messageReactionCount = "chat-module--messageReactionCount--16vLs";
export var messageReactionUsers = "chat-module--messageReactionUsers--1t8an";
export var messageReactionUser = "chat-module--messageReactionUser--20xdT";
export var messageEmojiPicker = "chat-module--messageEmojiPicker--3KYTc";
export var inputWrapper = "chat-module--inputWrapper--EWiu_";
export var inputAttachments = "chat-module--inputAttachments--1vfsp";
export var inputAttachment = "chat-module--inputAttachment--2CVuD";
export var inputAttachmentData = "chat-module--inputAttachmentData--1HF_j";
export var inputAttachmentPreview = "chat-module--inputAttachmentPreview--1cT5n";
export var inputAttachmentMenu = "chat-module--inputAttachmentMenu--1g5f-";
export var inputAttachmentName = "chat-module--inputAttachmentName--396ht";
export var inputAttachmentRemove = "chat-module--inputAttachmentRemove--SrWSB";
export var inputTooltip = "chat-module--inputTooltip--1-8GR";
export var iconTooltipContent = "chat-module--iconTooltipContent--215_3";
export var inputTooltipIcon = "chat-module--inputTooltipIcon--1H7SD";
export var input = "chat-module--input--HDuyG";
export var emojiPicker = "chat-module--emojiPicker--3Dp89";
export var inputTextarea = "chat-module--input-textarea--1wiEh";
export var inputIcon = "chat-module--inputIcon--3rdvj";
export var isButton = "chat-module--is-button--3tc6X";
export var iconWrapper = "chat-module--iconWrapper--8Jkwh";
export var iconTitle = "chat-module--iconTitle--1PZaJ";
export var icon = "chat-module--icon--2RmQj";
export var closeBtn = "chat-module--closeBtn--15wSn";